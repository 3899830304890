<template>
  <div class="wrapper">
    <Menu></Menu>
    <div class="body" style="padding: 0px 13px">

      <div class="row" v-for="(i, x) in allgamesdata" :key="getKey(x)" :id="i.category_name" style="background-color: #120028">

        <div v-show="i.category_id !== 24 && i.category_id !== 15 " class="casino-headers col-12" style="font-size: 1rem;margin-bottom: 6px;padding: 0 4px;background-color: #27273f;">
          <span v-text="i.category_name"></span>
        </div>

        <!-- new card design -->
        <div v-show="i.category_id !== 24 && i.category_id !== 15 " style="text-align: center;overflow-x: scroll;overflow-y: hidden;white-space: nowrap;width: 100%;">

          <div class="card col-lg-2 col-md-2 col-sm-2" v-for="(o, index) in i.games" :key="getKey(index)">

            <a :href="'#' + o.game_id">
              <img
                  :src="getImg(o.image)"
                  alt="Avatar"
                  style="
                  max-width: 100%;
                  height: 140px;
                  border-radius: 10px;
                  border: 1px solid #ddd;
                "
              />
            </a>

            <!-- modal here -->
            <div :id="o.game_id" class="modal-window" style="color: white">

              <div style="background-color: #353b40">

                <a href="#" title="Close" class="modal-close">X</a>

                <h1>{{ o.game_name }}</h1>

                <div>
                  <img :src="o.image" alt="gameimg" height="100"/>
                </div>

                <div class="text-center" v-show="i.category_id !== 24 && i.category_id !== 15 ">

                  <router-link v-show="parseInt(o.has_demo) === 1" :to="{name: 'casinogames',params: {gameName: o.game_name,launchURL: o.demo_url,launchType: 1,gameID: o.game_id},}">
                    <button class="btn btn-warning"><b>DEMO</b></button>
                  </router-link>

                  <router-link :to="{name: 'casinogames',
                      params: {gameName: o.game_name,gameID: o.game_id,launchURL: getLaunchURL(o.launch_url),launchType: o.launch_type,launchParameters: o.launch_parameters},}">
                    <button class="btn btn-success"><b>PLAY NOW</b></button>
                  </router-link>
                </div>

                <div class="text-center" v-show="i.category_id === 24 || i.category_id === 15 ">

                  <div v-show="parseInt(o.has_demo) === 1" @click="loadPage(o.demo_url)">
                    <button class="btn btn-warning"><b>DEMO</b></button>
                  </div>

                  <div  @click="loadPage(getLaunchURL(o.launch_url))" >
                    <button class="btn btn-success"><b>PLAY NOW</b></button>
                  </div>
                </div>

              </div>
            </div>
            <!-- modal here -->
          </div>
        </div>
        <!-- new card design -->
      </div>
    </div>
    <BottomFooter></BottomFooter>
    <bottom-navigation active-bets="0" page="freebet"></bottom-navigation>
  </div>
</template>
<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 40%;
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window > div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.modal-close:hover {
  color: black;
}

.container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal-window > div {
  border-radius: 1rem;
}

.modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
}
</style>
<script>
const Menu = () => import("@/components/casino/Menu.vue");
import BottomFooter from "@/components/BottomFooter";
import BottomNavigation from "@/components/BottomNavigation";
import axios from "@/services/api";

export default {
  name: "Xgames",
  components: {
    Menu,
    BottomFooter,
    BottomNavigation,
  },
  data: function () {
    return {
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      loading: "",
      code: "",
      action_verify_password: false,
      action_success: false,
      message: "",
      allgamesdata: [],
      provider_id: 1,
    };
  },

  mounted: function () {

    this.$store.dispatch("setCurrentPage", "casino");

    this.getCasinoGames();

    this.reloadProfile();

    var categories = [];

    var vm = this;

    this.jQuery.each(vm.games, function (k, v) {
      var key = v.Category.replaceAll(/ /g, "");
      var exists = false;

      // lets check if category exists
      vm.jQuery.each(categories, function (i, x) {

        if (x.key == key) {

          x.games.push(v);
          exists = true;
        }

        categories[i] = x;
      });

      if (!exists) {

        var gm = [];
        gm.push(v);

        var cat = {
          name: v.Category,
          key: key,
          games: gm,
        };

        categories.push(cat);
      }
    });

    this.groupedGames = categories;

    this.showSlides();

  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },

  methods: {

    loadPage: function(launch_url){
      window.open(launch_url, '_blank');
    },
    getImg: function (img){

      return img.replaceAll("/rec/325/","/square/200/")

    },
    showSlides: function () {

      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {

        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {

        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {

        dots[i].className = dots[i].className.replace(" active", "");

      }

      if(slides[slideIndex - 1]) {

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";

      }

      setTimeout(function (){

        vm.showSlides();

      },2000)  // Change image every 2 seconds

    },

    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;

      return launch_url.replaceAll("profile_token", tokn);

    },

    getKey: function (index) {

      return Math.random()
          .toString(10)
          .replace("0.", "games-id-" + index + "-");
    },

    getCasinoGames: function () {

      var platform = this.isMobile() ? 'MOBILE' : 'DESKTOP';
      var path = process.env.VUE_APP_CASINO_GAMES_URL;
      path = path.replace("{category_id}", 0);
      path = path.replace("{provider_id}", this.provider_id);
      path = path.replace("{platform}", platform);

      const passtoken = {};

      axios
          .post(path, passtoken)
          .then((resp) => {

            var allgames = resp.data.message;
            this.allgamesdata = allgames;
            console.log(allgames);

          });
    },

    getCasinoEvents: function (tableID){

      //return;

      var path = process.env.VUE_APP_PROVIDER_5_WSURL;
      var casinoID = process.env.VUE_APP_PROVIDER_5_CASINO_ID;

      var vm = this;

      // connect
      vm.DGA.connect(path);

      // on connect
      vm.DGA.onConnect = function () {

        // available
        vm.DGA.available(casinoID);

        // subscribe
        vm.DGA.subscribe(casinoID,tableID,'KES');

        vm.DGA.onMessage = function (data) {

          if(data) {

            console.log("TableID "+tableID+" Received onMessage ==> " + JSON.stringify(data, undefined, 2))

          }

        }

      }

    }
  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>